import React from "react";
import { Layout } from "antd";
import Headers from "./header/header";
import Footers from "./footer/Footer";
import ScrollToTopButton from "../common/ScrollToTopButton/ScrollToTopButton";

const { Header, Footer, Content } = Layout;
function Layouts({ children }) {
    return (

        <div>
            <Layout>
                <Header><Headers /></Header>
                <Content>{children}</Content>
                <Footer><Footers /></Footer>
                <ScrollToTopButton />
            </Layout>
        </div>
    )
}

export default Layouts



