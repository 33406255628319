import React from "react";
import "../../style/home/footer.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footers() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="Footers">
      <h1>{t("GET IN TOUCH")}</h1>
      <div className="footer-links">
        <p>
          <a
            href="/about-us"
            className="footer-link"
            onClick={() => navigate("/about-us")}
          >
            {t("About")}
          </a>
        </p>
        <p>
          <a href="/privacy-policy" className="footer-link">
            {t("Privacy Policy")}
          </a>
        </p>
        <p>
          <a href="/terms-and-conditions" className="footer-link">
            {t("Terms and Conditions")}
          </a>
        </p>
      </div>
    </div>
  );
}
