import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function App() {

  const { i18n } = useTranslation();
  const language = useSelector((state) => state.lang.language);


  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
