import LazyComponent from "./LazyComponent";
import Layouts from "../layout/Layouts";

export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      <Layouts>
        <LazyComponent path="home/HomePage/HomePage" />
      </Layouts>
    ),
    description: "home-Page",
  },
  businessProfile: {
    title: "businessProfileome",
    path: "/businessProfile",
    element: (
      <Layouts>
        <LazyComponent path="businessProfile/BusinessProfile" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  shop: {
    title: "shop-pages",
    path: "/shop",
    element: (
      <Layouts>
        <LazyComponent path="shop/Shop" />
      </Layouts>
    ),
    description: "Shop-Page",
  },
  Stepper: {
    title: "form-Page",
    path: "/form",
    element: (
      <Layouts>
        <LazyComponent path="Form/Stepper" />
      </Layouts>
    ),
    description: "form-page",
  },
  About: {
    title: "about-Page",
    path: "/about-us",
    element: (
      <Layouts>
        <LazyComponent path="about/About" />
      </Layouts>
    ),
    description: "about-page",
  },
  ContactUs: {
    title: "contact-Page",
    path: "/contact",
    element: (
      <Layouts>
        <LazyComponent path="contactus/ContactUs" />
      </Layouts>
    ),
    description: "contact-page",
  },
  BlogPage: {
    title: "blog-Page",
    path: "/blogs",
    element: (
      <Layouts>
        <LazyComponent path="blog/BlogPage" />
      </Layouts>
    ),
    description: "contact-page",
  },
  FAQ: {
    title: "faq-Page",
    path: "/faq",
    element: (
      <Layouts>
        <LazyComponent path="faq/FAQ" />
      </Layouts>
    ),
    description: "faq-page",
  },

  payment: {
    title: "payment",
    path: "/payment",
    element: (
      <Layouts>
        <LazyComponent path="payment/paymentForm" />
      </Layouts>
    ),
    description: "payment-form-Page",
  },
};
