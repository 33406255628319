import React, { useState, useEffect } from "react";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import "../../style/home/header.scss"; // Ensure correct path to your SCSS file
import { useTranslation } from "react-i18next";
import { dispatchlanguage } from "../../redux/reduxSlice/languageSlice";
import i18next from "../../services/i18next";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../assets";

const { Option } = Select;

const languageFlags = {
  en: images.flagUSAIcon,
  fr: images.flagFrench,
  es: images.flagSpanish,
  de: images.flagGerman,
};

export default function Headers() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("HOME");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.lang.language);
  useEffect(() => {
    // Set initial language from local storage if available
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      dispatch(dispatchlanguage(storedLanguage));
      i18n.changeLanguage(storedLanguage);
    }
  }, [dispatch, i18n]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (key) => {
    setCurrent(key);
    setDrawerVisible(false);
    if (key === "HOME") {
      navigate("/");
    } else {
      navigate(`/${key.toLowerCase().replace(" ", "-")}`);
    }
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleLanguageChange = (value) => {
    dispatch(dispatchlanguage(value));
    i18n.changeLanguage(value);
    localStorage.setItem("selectedLanguage", value);
    navigate( { state: { language: value } });
  };

  return (
    <header className="main-navbar">
      <div className="container-navbar">
        <div className="navbar-left">
          {isMobile && (
            <div className="menu-icon" onClick={showDrawer}>
              <MenuOutlined style={{ fontSize: "25px", color: "#1890ff" }} />
            </div>
          )}
          <div className="navbar-brand">
            <img
              src={images.mainlogowhite}
              alt="Website Logo"
              className="logo"
              style={{ width: "90px", height: "70px", marginTop: "20px" }}
            />
          </div>
        </div>
        {!isMobile && (
          <nav className="navbar-nav">
            <ul className="nav-list">
              <li
                className={`nav-item ${current === "HOME" ? "active" : ""}`}
                onClick={() => handleClick("HOME")}
               
              >
              <span style={{cursor:"pointer"}}>

                {t("home")}
              </span>
              </li>
              <li
                className={`nav-item ${current === "ABOUT US" ? "active" : ""}`}
                onClick={() => handleClick("ABOUT US")}
              >
              <span style={{cursor:"pointer"}}>

                {t("aboutus")}
              </span>
              </li>
              <li
                className={`nav-item ${current === "CONTACT" ? "active" : ""}`}
                onClick={() => handleClick("CONTACT")}
              >
              <span style={{cursor:"pointer"}}>

                {t("contact")}
              </span>
              </li>
              <li
                className={`nav-item ${current === "BLOGS" ? "active" : ""}`}
                onClick={() => handleClick("BLOGS")}
              >
              <span style={{cursor:"pointer"}}>

                {t("blog")}
              </span>
              </li>
              <li
                className={`nav-item ${current === "FAQ" ? "active" : ""}`}
                onClick={() => handleClick("FAQ")}
              >
              <span style={{cursor:"pointer"}}>

                {t("faq")}
              </span>
              </li>
            </ul>
          </nav>
        )}
        <div className="navbar-action">

          <Select
            className="language-select"
            value={language}
            style={{ width: 140, height:"40px" , marginTop:"5px" }}
            suffixIcon={<img src={languageFlags[language]} alt="Language" style={{ width: '30px',marginTop:"px" }} />}
            onChange={handleLanguageChange}
          >
            <Option value="en">English</Option>
            <Option value="fr">French</Option>
            <Option value="es">Spanish</Option>
            <Option value="de">German</Option>
          </Select>
          <Button
            type="primary"
            className="login-btn"
            onClick={() => navigate("/form")}
          >
            {t("proceed")}
          </Button>
        </div>
      </div>
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={onCloseDrawer}
        visible={drawerVisible}
        className="mobile-drawer"
      >
        <div className="drawer-content">
          <div className="search-wrapper">
            <Input
              placeholder={t("search")}
              suffix={<SearchOutlined />}
              className="search-input"
              style={{ marginBottom: "15px" }}
            />
          </div>
          <Select
            className="language-select"
            value={language}
            style={{ width: "100%", marginBottom: "15px" }}
            suffixIcon={<img src={languageFlags[language]} alt="Language" style={{ width: '24px', height: '24px' }} />}
            onChange={handleLanguageChange}
          >
            <Option value="en">English</Option>
            <Option value="fr">French</Option>
            <Option value="es">Spanish</Option>
            <Option value="de">German</Option>
          </Select>
          <ul className="nav-list">
            <li
              className={`nav-item ${current === "HOME" ? "active" : ""}`}
              onClick={() => handleClick("HOME")}
            >
              {t("home")}
            </li>
            <li
              className={`nav-item ${current === "ABOUT US" ? "active" : ""}`}
              onClick={() => handleClick("ABOUT US")}
            >
              {t("aboutus")}
            </li>
            <li
              className={`nav-item ${current === "CONTACT" ? "active" : ""}`}
              onClick={() => handleClick("CONTACT")}
            >
              {t("contact")}
            </li>
            <li
              className={`nav-item ${current === "BLOGS" ? "active" : ""}`}
              onClick={() => handleClick("BLOGS")}
            >
              {t("blog")}
            </li>
            <li
              className={`nav-item ${current === "FAQ" ? "active" : ""}`}
              onClick={() => handleClick("FAQ")}
            >
              {t("faq")}
            </li>
          </ul>
        </div>
      </Drawer>
    </header>
  );
}
