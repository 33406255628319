import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  test: null,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    saveFormData: (state, action) => {
      // state.test = { ...state.formData, ...action.payload };
      state.formData = { ...state.formData, ...action.payload };
      return;
    },
    clearFormData: (state) => {
      state.formData = initialState.formData;
      // state.test = initialState.test;
    },
  },
});

export const { saveFormData, clearFormData } = formSlice.actions;
export default formSlice.reducer;
