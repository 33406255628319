import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'lang',
  initialState: {
    language: 'en',
  },
  reducers: {
    dispatchlanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { dispatchlanguage } = languageSlice.actions;
export default languageSlice.reducer;
