// src/redux/uploadImage/UploadImage.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadedImage: null,
};

const uploadImageSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUploadedImage: (state, action) => {
      state.uploadedImage = action.payload;
    },
    clearUploadedImage: (state) => {
      state.uploadedImage = null;
    },
  },
});

export const { setUploadedImage, clearUploadedImage } = uploadImageSlice.actions;
export default uploadImageSlice.reducer;
