// import { configureStore } from '@reduxjs/toolkit';
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import languageReducer from '../reduxSlice/languageSlice';
// import formReducer from '../formSlice/FormSlice';

// const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist: ['lang'],
// };

// const rootReducer = {
//     lang: persistReducer(persistConfig, languageReducer),
//     form: persistReducer(persistConfig, formReducer),
// };

// const store = configureStore({
//     reducer: rootReducer,
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({
//             serializableCheck: {
//                 ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
//             },
//         }),
// });

// const persistor = persistStore(store);

// export { store, persistor };


import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import languageReducer from '../reduxSlice/languageSlice';
import formReducer from '../formSlice/FormSlice';
import uploadReducer from '../uploadImage/UploadImage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['lang'],
};

const rootReducer = {
  lang: persistReducer(persistConfig, languageReducer),
  form: persistReducer(persistConfig, formReducer),
  upload: uploadReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Adjust ignoredActions and ignoredPaths based on your specific needs
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'your/other/action/type'],
        ignoredPaths: ['some/complex/object', 'array.of.objects.with.circular.refs'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

