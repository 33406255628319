export const images = {
  logo: require("./home/Asia-247x296.png"),
  mainlogo: require("./home/2.png"),
  mainlogowhite: require("./home/1.png"),
  languageIcon: require("./home/language (2).png"),
  sectionSideImg: require("./home/NorthAmerica-1-247x296.png"),
  section: require("./home/ad-247x296.png"),
  avatorImg: require("./home/ag-247x167.png"),
  icon1Home: require("./home/ai-247x125.png"),
  icon2Home: require("./home/ar-247x156.png"),
  icon3Home: require("./home/armenia-flag-wave-medium-247x296.jpg"),
  icon4Home: require("./home/au-247x125.png"),
  icon5Home: require("./home/dz-247x296 (1).png"),
  icon6Home: require("./home/vecteezy_albania-national-flag-wallpaper-background_8055263-247x296.jpg"),
  team1: require("./home/team1.jpeg"),
  team2: require("./home/team2.jpg"),
  team3: require("./home/team3.jpeg"),
  team4: require("./home/team4.jpeg"),
  blog1: require("./home/blog/Cinderella-stories-around-the-world-GTK-1024x536.png"),
  blog2: require("./home/blog/Exploring-Houses-Around-the-World-activities-1024x536.png"),
  blog3: require("./home/blog/food-for-the-future-sustainable-farms-around-the-world-book-1024x536.png"),
  blog4: require("./home/blog/ganesha-goes-green-review-1024x536.png"),
  blog5: require("./home/blog/little-passports-subscription-boxes-for-kids-1-1024x536.png"),
  blog6: require("./home/blog/morning-meeting-elementary-classroom-1024x536.png"),
  blog7: require("./home/blog/rumi-poet-of-joy-and-love-1024x536.png"),
  flagEnglish: require("./home/flagImages/englishFlag.jpeg"),
  flagFrench: require("./home/flagImages/frenchFlag.jpeg"),
  flagSpanish: require("./home/flagImages/spainIcon.jpeg"),
  flagGerman: require("./home/flagImages/german.png"),
  flagEnglishIcon: require("./home/flagImages/iconFlags/united-kingdom-flag-icon.png"),
  flagFrenchIcon: require("./home/flagImages/iconFlags/france-flag-icon.png"),
  flagSpanishIcon: require("./home/flagImages/iconFlags/spain-country-flag-icon.png"),
  flagGermanIcon: require("./home/flagImages/iconFlags/germany-flag-icon.png"),
  flagUSAIcon: require("./home/flagImages/iconFlags/USA-flag.avif"),
  imageRequirement: require("./home/imageRequirements/Image-Requirement.jpg"),
  formatImage: require("./home/imageRequirements/Format-Images.jpg"),
  noShadows: require("./home/imageRequirements/no shadow.jpg"),
  shadows: require("./home/imageRequirements/shadow.jpg"),
  headScarf: require("./home/imageRequirements/headscarf.jpg"),
  noGlasses: require("./home/imageRequirements/glasses.jpg"),
  headPosition: require("./home/imageRequirements/head-position.jpg"),
  faceExpression: require("./home/imageRequirements/face-expression.jpg"),
};
