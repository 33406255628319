import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from "../translations/english.json"
import french from "../translations/french.json"
import german from "../translations/german.json"
import spanish from "../translations/spanish.json"

export const languageResources = {
  fr: { translation: french },
  en: { translation: english },
  de: { translation: german },
  es: { translation: spanish },
};

i18next.use(initReactI18next).init({
  resources: languageResources,
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
});

export default i18next;
